import React from "react";
import Page from "../../components/global/Page";
import Privacy from "../../components/global/Privacy";

const PrivacyPage = () => {
  return (
    <Page title="Aviso de Privacidad">
      <Privacy />
    </Page>
  );
};

export default PrivacyPage;
