import React, { useContext, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";

const AcceptTermsModal = ({ name, acceptKey, children = [] }) => {
  const [accept, setAccept] = useState(false);

  const { user, updateUsuario } = useContext(AuthContext);
  const { alert, clearModal } = useContext(ModalContext);

  const handleSubmit = () => {
    if (!accept) {
      return alert(`Debes aceptar ${name} para continuar`);
    }
    user[acceptKey] = moment().format();
    updateUsuario(user, `Has aceptado ${name}`);
    clearModal();
  };

  return (
    <div className="">
      <div
        className="border p-3 bg-light"
        style={{ maxHeight: 500, height: "100%", overflowY: "scroll" }}
      >
        {children}
      </div>
      <div className="container-fluid px-0 py-2 mb-2">
        <label>
          <input
            type="checkbox"
            checked={accept}
            className="d-inline-block me-2"
            onChange={() => setAccept(!accept)}
          />
          Acepto {name}
        </label>
      </div>
      <div className="row">
        <div className="col-6" />
        <div className="col-6">
          <button onClick={handleSubmit} className="btn btn-primary w-100">
            Aceptar
          </button>
        </div>
      </div>
    </div>
  );
};
export default AcceptTermsModal;
