import React, { useEffect, useContext } from "react";
import PreguntasFrecuentes from "./components/global/PreguntasFrecuentes";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import { AuthContext } from "./context/AuthContext";
import SinglePage from "./views/pages/SinglePage";
import Modal from "./components/common/Modal";
import Recuperar from "./views/Recuperar";
import Checkout from "./views/Checkout";
import { Router } from "@reach/router";
import Gracias from "./views/Gracias";
import Loading from "./views/Loading";
import SignUp from "./views/SignUp";
import Login from "./views/Login";
import Panel from "./views/Panel";
import Home from "./views/Home";
import Courses from "./views/Courses";
import LiveCourses from "./views/LiveCourses";
import WhatsApp from "./components/global/WhatsApp";
import OnDemandCourses from "./views/OnDemandCourses";
import TermsPage from "./views/pages/TermsPage";
import ClausesPage from "./views/pages/ClausesPage";
import PrivacyPage from "./views/pages/PrivacyPage";

const Main = () => {
  const { user, userLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    userLoggedIn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid px-0">
      <Router>
        <Home path="/*" />
        <Login path="/entrar" />
        <Courses path="/courses" />
        <SignUp path="/registro" />
        <LiveCourses path="/live" />
        <Recuperar path="/recuperar" />
        <OnDemandCourses path="/ondemand" />
        <Gracias path="/thankyou/:purchase_id" />
        <Checkout path="/checkout/:product_id" />
        {/* Pages */}
        <SinglePage path="/pages/:slug" />
        <TermsPage path="/terminos" />
        <ClausesPage path="/clausulas" />
        <PrivacyPage path="/privacidad" />
        <PreguntasFrecuentes path="/preguntas-frecuentes" />
        {user !== null ? (
          <Panel path="/mytbm/*" />
        ) : (
          <Loading path="/mytbm/*" />
        )}
      </Router>
      <WhatsApp />
      <Modal />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;
