import React from "react";

const BBPClauses = () => {
  return (
    <div>
      <h4>Block Burn Pilates</h4>
      <p>
        USO CORRECTO DEL NOMBRE – se concede exclusivamente el derecho de usar
        el nombre{" "}
        <i className="me-2">
          <span>
            <u>Block Burn Pilates by TBM</u>
          </span>
        </i>
        para utilizarlo y referirlo de manera correcta sin que se omita
        fonéticamente ningún elemento. Para otorgarse tal derecho es necesario
        mencionar <u>by TBM</u> en todo tipo de material promocional.
      </p>

      <p>
        VIGENCIA – el uso activo de la certificación tiene una vigencia de 01
        año, respectivamente.
      </p>

      <p>
        RENOVACIÓN – para cumplir la acreditación de la renovación de vigencia,
        se deberá cumplir con los términos de pago para extensión de vigencia,
        así como de los requisitos que sean complementarios al pago en cuestión.
      </p>
      <p>
        El costo de renovación es de $1200 (
        <i>
          <span>mil doscientos pesos 00/100 MN</span>
        </i>
        ) y su validez de vigencia aplicará por una extensión de 01 año
        adicional para licencia de uso de nombre.
      </p>
      <p>
        ACTUALIZACIÓN DE CERTIFICACIONES RENOVADAS – un beneficio que se genera
        para todas las usuarias cuando completan su proceso de renovación, es
        que se generará un taller{" "}
        <i>
          <span>on demand</span>
        </i>{" "}
        de educación continua para mantenerlas actualizadas sobre conocimientos
        nuevos generados dentro de la misma certificación.
      </p>
      <p>
        CERTIFICADO DE USO – se otorgará un certificado oficial a las personas
        autorizadas para usar el nombre de la certificación dentro de la
        vigencia establecida, una vez que haya sido completada su certificación{" "}
        <i>
          <span>
            <u>Block Burn Pilates by TBM.</u>
          </span>
        </i>
      </p>
      <p>
        REPORTE ANUAL – una vez que una persona certificada cumpla con su
        primera renovación anual,{" "}
        <i>
          <span>
            y como parte de los requisitos para acreditar la implementación de
            BBP by TBM en sus clases
          </span>
        </i>
        , se agendará un reporte anual que será actualizado periódicamente año
        tras año.&nbsp;
      </p>
      <p>
        PROHIBICIÓN DE CAMBIOS – las personas certificadas para usar el nombre{" "}
        <i>
          <span>
            <u>Block Burn Pilates by TBM</u>
          </span>
        </i>{" "}
        tienen estrictamente prohibido realizar cualquier modificación al método
        sin que hubiera autorización de por medio acreditada, o de lo contrario
        aplicarán penalizaciones en caso de tal incumplimiento.
      </p>
      <p>
        DIRECTORIO OFICIAL DE INSTRUCTORAS – acreditar las certificaciones de{" "}
        <i>
          <span>
            <u>Block Burn Pilates by TBM</u>
          </span>
        </i>{" "}
        generan el derecho de aparecer en el directorio oficial de instructoras
        certificadas en nuestra página web, siempre y cuando exista
        consentimiento en firmar una autorización digital para aparecer en este
        listado público, llenando un formulario e incluyendo los datos que se
        desean compartir.
      </p>
      <p>
        Si existieran clientes o estudios interesados en contratar instructoras
        certificadas, podrán consultar el Directorio Oficial de Instructoras
        para buscar clases con una instructora certificada.&nbsp;
      </p>
      <p>
        El Directorio de Instructoras es nuestra herramienta para validar quién
        tiene la certificación oficial.
      </p>
    </div>
  );
};

export default BBPClauses;
