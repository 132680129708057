import React, { useContext, useEffect } from "react";
import { CoursesContext } from "../../context/CoursesContext";
import SingleCourse from "./SingleCourseCard";
import SingleCourseLanding from "./SingleCourseLanding";
import { AuthContext } from "../../context/AuthContext";

const CourseGrid = ({ isPanel, landing, course_accesses }) => {
  const { user } = useContext(AuthContext);
  const { courses, getMyCourses, getCourses } = useContext(CoursesContext);

  useEffect(() => {
    if (isPanel) {
      getMyCourses();
    } else {
      getCourses();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCourses = () => {
    let CustomComponent = SingleCourse;
    if (landing) CustomComponent = SingleCourseLanding;
    if (Array.isArray(courses)) {
      let coursesRender = [...courses];
      if (window.location.pathname.includes("mytbm")) {
        coursesRender = coursesRender.filter(
          (course) => ![4, 5].includes(course.course_id)
        );
      }
      return coursesRender.map((course) => (
        <div key={course.course_id} className="col-12 col-md-6 col-xl-4 mb-3">
          <CustomComponent
            isPanel
            course={course}
            course_accesses={course_accesses}
            completed_courses={user !== null ? user.completed_courses : []}
          />
        </div>
      ));
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div id="courses-grid" className="row mx-0">
      {renderCourses()}
    </div>
  );
};

export default CourseGrid;
