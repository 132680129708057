import React, { useContext } from "react";
import CourseGrid from "../components/home/CourseGrid";
import { CourseAccessesContext } from "../context/CourseAccessesContext";

const PanelHome = () => {
  const { course_accesses } = useContext(CourseAccessesContext);

  return (
    <div id="panel-home" className="pt-5">
      <div className="container-fluid">
        <CourseGrid isPanel course_accesses={course_accesses} />
      </div>
    </div>
  );
};

export default PanelHome;
