import React from "react";
import Page from "../../components/global/Page";
import Terms from "../../components/global/Terms";

const TermsPage = () => {
  return (
    <Page title="Términos y Condiciones">
      <Terms />
    </Page>
  );
};

export default TermsPage;
