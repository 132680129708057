import React from "react";

const Privacy = () => {
  return (
    <div>
      <p>
        El presente documento constituye el “Aviso de Privacidad” de TBM The
        Body Method en donde se establece la manera en que será tratada su
        información por parte de TBM The Body Method así como la finalidad para
        la que fue recabada, lo anterior de conformidad con los Artículos 15 y
        16 de Ley Federal de Protección de Datos Personales en Posesión de los
        Particulares (la “Ley”).{" "}
      </p>
      <p>
        TBM The Body Method., respeta su derecho a la privacidad y protección de
        datos personales, datos personales sensibles, incluyendo sus datos
        financieros o patrimoniales, los cuales están amparados bajo la Ley. La
        base de datos en donde consta su información es responsabilidad de TBM
        The Body Method., la cual se encuentra ubicada en Plaza Vía 02,
        Boulevard Rogelio Cantú Gómez, número exterior 1000, número interior 79,
        en la Colonia Las Lajas, en Monterrey, Nuevo León con código postal
        64638. Asimismo, su información se encuentra debidamente resguardada
        conforme a las disposiciones de seguridad administrativa, técnica y
        física, establecidas en la Ley de la materia, para protegerla de los
        posibles daños, perdidas, alteración o acceso no autorizado.
      </p>
      <h3>Datos Sensibles</h3>
      <p>
        El Titular de la Información reconoce y acepta, que debido a su relación
        con TBM The Body Method., no ha proporcionado, ni tendrá que
        proporcionar “datos personales sensibles”, es decir, aquellos datos
        personales íntimos o cuya realización debida o indebida pueda dar origen
        a discriminación o conlleve un riesgo grave para éste. En el supuesto de
        que el Titular de la Información proporcione datos del tipo de los
        llamados sensibles, deberá estar de acuerdo en proporcionarlos
        previamente y dejará a TBM The Body Method., libre de cualquier queja o
        reclamación respectiva.{" "}
      </p>
      <h3>Solicitudes</h3>
      <p>
        El titular podrá ejercer los derechos de acceso, rectificación,
        cancelación u oposición, respecto a los datos personales que le
        conciernen, así como también solicitar la revocación de su
        consentimiento, enviando una solicitud a TBM The Body Method., a través
        del teléfono 8197365425 o ingresando al sitio web
        https://www.thebodymethod.mx, en donde de igual manera se le dará a
        conocer el procedimiento para poder ejercer los derechos ARCO y para la
        atención de las solicitudes de revocación del consentimiento, indicando
        por lo menos su nombre y domicilio completo o cualquier otro dato o
        documento que permita su identificación, así como el objeto de su
        solicitud y/o tramite a efectuar. Lo anterior se deberá realizar en base
        a la Ley y su Reglamento.{" "}
      </p>
      <h3>Finalidad</h3>
      <p>
        Sus datos personales e historial como cliente serán utilizados por TBM
        The Body Method., y quedarán registrados en nuestra base de datos, así
        como cualquier otra información personal, sensible o de cualquier otra
        índole, siempre que se cumpla con lo establecido en la Ley y su
        Reglamento respecto a cada tipo de información. Esta información puede
        ser utilizada por TBM The Body Method., para cualquiera de las
        siguientes finalidades:
      </p>
      <p>a. Finalidad Comercial</p>
      <p>
        (i) Nombre del Titular de la Información, domicilio, RFC, teléfono,
        número de cuenta de facturación, fecha de nacimiento, datos bancarios.
        Esta información podrá usarse de manera enunciativa más no limitativa
        para: (1) Ofrecerle productos y servicios, ya sea de manera física,
        telefónica, electrónica o por cualquier otra tecnología o medio que esté
        al alcance de TBM The Body Method., (2) Hacer de su conocimiento nuevos
        productos o servicios que puedan ser de interés en relación con el
        servicio contratado, (3) Realizar para su comodidad los cargos
        facturados de manera automática, (4) Emitir órdenes de compra, (5)
        Cualquier otra acción que sea necesaria para cumplir con los intereses
        de TBM The Body Method., respecto al acuerdo que haya llegado con el
        Titular de la Información.
      </p>
      <p>b. Finalidad de seguridad en el acceso a las instalaciones.</p>
      <p>
        Esta información podrá usarse de manera enunciativa más no limitativa
        para: (1) Respaldar, registrar y/o controlar el registro de las personas
        que accedan o visiten las instalaciones de TBM The Body Method., (2)
        Cumplir con los lineamientos de seguridad establecidos en las políticas
        internas de la Empresa. En relación con la información que se solicite
        para ingresos a instalaciones de TBM The Body Method., se podrá pedir
        información como nombre completo, firma, identificación oficial, así
        como información de equipo electrónico que se vaya a ingresar a las
        instalaciones de TBM The Body Method.
      </p>
      <h3>Transferencia</h3>
      <p>
        El Titular de la Información entiende y acepta que TBM The Body Method.,
        podrá transferir sus datos personales a terceros que han sido
        contratados por TBM The Body Method., para que realicen en su nombre y
        representación ciertas tareas relacionadas con las actividades
        comerciales y de promoción de sus productos y/o servicios. Estas
        terceras partes pueden tratar los datos en cumplimiento de las
        instrucciones de TBM The Body Method., o tomar decisiones sobre ellos
        como parte de la prestación de sus servicios. En cualquiera de los dos
        casos, TBM The Body Method., seleccionará proveedores que considere
        confiables y que se comprometan, mediante un contrato u otros medios
        legales aplicables, a implementar las medidas de seguridad necesarias
        para garantizar un nivel de protección adecuado a sus datos personales.
        Derivado de lo anterior TBM The Body Method., exigirá a sus proveedores
        que cumplan con medidas de seguridad que garanticen los mismos niveles
        de protección que TBM The Body Method., implementa durante el
        tratamiento de sus datos como cliente de TBM The Body Method. Estas
        terceras partes seleccionadas tendrán acceso a su información con la
        finalidad de realizar las tareas especificadas en el contrato de
        servicios aplicable que haya suscrito con TBM The Body Method., Si TBM
        The Body Method., determina que un proveedor no está cumpliendo con las
        obligaciones pactadas, tomará inmediatamente las acciones pertinentes.{" "}
      </p>
      <p>
        Si el titular, ya no acepta la transmisión de sus datos personales de
        conformidad con lo estipulado en el párrafo anterior, puede ponerse en
        contacto con TBM The Body Method., por cualquiera de los medios
        establecidos en el presente Aviso de Privacidad.{" "}
      </p>
      <h3>Excepciones</h3>
      <p>
        Adicionalmente y de conformidad con lo estipulado en los Artículos 10,
        37 y demás relativos de la Ley y su Reglamento, TBM The Body Method.,
        quedara exceptuado de las obligaciones referentes al consentimiento para
        el Tratamiento y Transferencia de sus Datos, cuando:
      </p>
      <ol>
        <li>Esté previsto en una Ley;</li>
        <li>Los datos figuren en fuentes de acceso público;</li>
        <li>
          Los datos personales se sometan a un procedimiento previo de
          disociación;
        </li>
        <li>
          Tenga el propósito de cumplir obligaciones derivadas de una relación
          jurídica entre el titular y el responsable;
        </li>
        <li>
          Exista una situación de emergencia que potencialmente pueda dañar a un
          individuo en su persona o en sus bienes;
        </li>
        <li>
          Sean indispensables para la atención médica, la prevención,
          diagnóstico, la prestación de asistencia sanitaria, tratamientos
          médicos o la gestión de servicios sanitarios;
        </li>
        <li>Se dicte resolución de autoridad competente;</li>
        <li>
          Cuando la transferencia sea precisa para el reconocimiento, ejercicio
          o defensa de un derecho en un proceso judicial, y
        </li>
        <li>
          Cuando la transferencia sea precisa para el mantenimiento o
          cumplimiento de una relación jurídica entre el responsable y el
          titular.
        </li>
      </ol>
      <h3>Modificaciones</h3>
      <p>
        Ambas partes, en este acto acuerdan, que en caso de que se requiera
        alguna modificación a lo estipulado en el presente Aviso de Privacidad,
        TBM The Body Method., se obliga a hacer del conocimiento los cambios que
        en su caso se requieran, por cualquier medio, incluidos los
        electrónicos, previo aviso que se le dé a Usted para que se manifieste
        por su parte, lo que a su derecho convenga, ya que de no recibir
        negativa expresa y por escrito de su parte, o bien, respuesta alguna, se
        entenderá que Usted acepta de conformidad los cambios realizados.
      </p>
      <h3>Consentimiento del Titular</h3>
      <p>
        El Titular de la Información reconoce y acepta que en caso de que este
        “Aviso de Privacidad” esté disponible a través de una página electrónica
        (sitio Web, página de Internet o similar) o algún otro dispositivo
        electrónico, al hacer clic en “aceptar” o de cualquier otra forma seguir
        navegando en el sitio, o bien al proporcionar sus Datos a través del
        mismo o a través de cualquier medio electrónico (correo electrónico,
        etc.), constituye una manifestación de su consentimiento para que TBM
        The Body Method., realice el tratamiento de sus Datos, de conformidad
        con este Aviso de Privacidad.
      </p>
      <p>
        Asimismo, manifiesta que en caso de que este “Aviso de Privacidad” esté
        disponible por escrito, su firma, rúbrica, nombre o huella o bien al
        proporcionar sus Datos, constituye una manifestación de su
        consentimiento para que TBM The Body Method., realice el tratamiento de
        sus datos, de conformidad con este “Aviso de Privacidad”.
      </p>
      <h3>Notificación de cambios del aviso</h3>
      <p>
        Si TBM The Body Method., cambia este aviso de privacidad, dichos cambios
        aparecerán en el sitio web https://www.thebodymethod.mx
      </p>
      <p>
        Si usted tiene alguna pregunta o desea ejercer algún derecho relacionada
        con esta declaración de privacidad, por favor comuníquese con TBM The
        Body Method., al departamento de Seguridad de la Información, usando la
        dirección: thebodymethodmty@gmail.com
      </p>
      <p>
        Este aviso de privacidad ha sido creado en base a las reglas expedidas
        por la Ley de Protección de Datos Personales con fundamento en los
        artículos 16, 17, 36 de la presente Ley y corresponsales de su
        Reglamento.
      </p>
    </div>
  );
};

export default Privacy;