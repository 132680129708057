import React, { useState, useContext, useEffect } from "react";
import { CoursesContext } from "../../context/CoursesContext";
import ProgressBar from "../common/ProgressBar";
import { SectionsContext } from "../../context/SectionsContext";
import AttemptFinalExam from "../tests/AttemptFinalExam";
import ContinueFinalExam from "../tests/ContinueFinalExam";
import FinalExamGraded from "../tests/FinalExamGraded";
import { TestsContext } from "../../context/TestsContext";
import moment from "moment";
import { getCourseLastAttempt, getCourseFinalExam } from "../../utils/course";
import { navigate } from "@reach/router";

const ProgressCourse = ({ course_id }) => {
  const [hasCompletedCourse, setHasCompletedCourse] = useState(false);
  const [lastAttempt, setLastAttempt] = useState(null);

  const { tests, getTestsCourse } = useContext(TestsContext);
  const { sections } = useContext(SectionsContext);
  const { course } = useContext(CoursesContext);

  useEffect(() => {
    getTestsCourse(course_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Array.isArray(sections)) {
      let completedSections = sections.filter(
        ({ completed, tests }) => completed.length > 0 || tests.length === 0
      );
      const allCompleted = completedSections.length >= sections.length;
      setHasCompletedCourse(allCompleted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  useEffect(() => {
    if (Array.isArray(tests)) {
      const attempt = getCourseLastAttempt({ tests });
      setLastAttempt(attempt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tests]);

  const getProgress = () => {
    if (course.videos > 0) {
      return (parseInt(course.completed) / parseInt(course.videos)) * 100;
    }
  };

  const getExamDate = () => {
    const { expiration_date } = course;
    if (expiration_date && expiration_date !== null) {
      return moment(expiration_date).utc().add(7, "days");
    }
  };

  const canStartExam = () => {
    const examDate = getExamDate();
    if (examDate && examDate !== null) {
      return moment()
        .utc()
        .startOf("day")
        .isSameOrAfter(examDate.subtract(7, "days"));
    }
  };

  const renderPreviousAttempts = () => {
    const finalExam = getCourseFinalExam({ tests });
    if (
      lastAttempt &&
      lastAttempt !== null &&
      finalExam &&
      finalExam !== null
    ) {
      const previousAttempts = finalExam.attempts.filter(
        ({ attempt_id }) => attempt_id !== lastAttempt.attempt_id
      );
      if (previousAttempts.length > 0)
        return (
          <div>
            <div className="container-fluid bg-dark">
              <h3 className="h5 text-white">Intentos Previos</h3>
              {previousAttempts.map((attempt) => (
                <div
                  key={attempt.attempt}
                  className="row border-top align-items-center py-3"
                >
                  <div className="col-12 col-md-6">
                    <h4 className="h6 text-white">
                      {moment(attempt.finishedAt).format("DD / MMM / YYYY")}
                    </h4>
                    <p className="text-white">{attempt.score}</p>
                  </div>
                  <div className="col-12 col-md-6 text-end">
                    <button
                      className="btn btn-light"
                      onClick={() =>
                        navigate(`/mytbm/attempt/${attempt.attempt_id}`)
                      }
                    >
                      Ver Resultado
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
    }
  };

  const renderFinalExam = () => {
    if (Array.isArray(sections) && Array.isArray(tests)) {
      if (hasCompletedCourse || canStartExam()) {
        if (lastAttempt && lastAttempt !== null) {
          if (lastAttempt.finishedAt === null) {
            return (
              <ContinueFinalExam
                course_id={course.course_id}
                attempt_id={lastAttempt.attempt_id}
              />
            );
          }
          if (lastAttempt.score === null) {
            return (
              <div className="bg-accent text-white p-4">
                <h3>Estamos Calificando tu Examen</h3>
                <p>Pronto tendrás tus resultados.</p>
              </div>
            );
          }
          return <FinalExamGraded attempt_id={lastAttempt.attempt_id} />;
        }
        return <AttemptFinalExam course={course} />;
      }
    }
  };

  const formatMomentDate = (date) => {
    if (date && date !== null) {
      return date.format("DD/MMM/YYYY");
    }
  };

  const getStartDate = () => {
    const { start_date } = course;
    if (start_date && start_date !== null) {
      return moment(start_date).utc();
    }
  };

  const getModulesDate = () => {
    const { expiration_date } = course;
    if (expiration_date && expiration_date !== null) {
      return moment(expiration_date).utc();
    }
  };

  return (
    <div className="px-4 pt-5 pb-3">
      <div className="px-3">
        <div className="text-white bg-dark d-flex flex-column flex-lg-row">
          <div className="d-flex flex-row flex-lg-column col-12 col-lg-2 justify-content-between justify-content-lg-none mt-3 mt-lg-0">
            <div className="ps-3 h3 mt-lg-3">
              <h2 className="mb-0">{course.name}</h2>
            </div>
            <div className="px-3 px-lg-0 h6 small mt-2 mt-lg-0 ps-lg-3">
              <p>Inicio: {formatMomentDate(getStartDate())}</p>
            </div>
          </div>
          <div className="d-flex flex-column mt-2 mt-lg-4 ps-3 ps-lg-5 px-3 px-lg-0 col-12 col-lg-9 px-3 pb-3 px-lg-0">
            <div className="col-12 mt-lg-2">
              <ProgressBar value={getProgress()} />
            </div>
            <div className="row align-items-center mt-3">
              <div className="col-4">
                <span className="h6 small">
                  {course.completed} / {course.videos} videos Completados
                </span>
              </div>
              <div className="col-4 text-end">
                <span className="h6 small">
                  Fecha Límite para ver Videos:{" "}
                  {formatMomentDate(getModulesDate())}
                </span>
              </div>
              <div className="col-4 text-end">
                <span className="h6 small">
                  Fecha Limite para Subir tu Examen:{" "}
                  {formatMomentDate(getExamDate())}
                </span>
              </div>
            </div>
          </div>
        </div>
        {renderPreviousAttempts()}
        {renderFinalExam()}
      </div>
    </div>
  );
};

export default ProgressCourse;
