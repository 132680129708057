import React from "react";
import Page from "../../components/global/Page";
import GeneralClauses from "../../components/global/GeneralClauses";

const ClausesPage = () => {
  return (
    <Page title="Cláusulas Especiales">
      <GeneralClauses />
    </Page>
  );
};

export default ClausesPage;