import React from "react";

const GeneralClauses = () => {
  return (
    <div>
      <p>
        <span>
          <span>
            ACREDITACIÓN E IMPARTICIÓN – la usuaria que acredita cualquier
            certificación únicamente puede validar su carácter de alumna, ya que
            la certificación no genera ningún derecho a impartir tal
            certificación fuera del programa de entrenamiento.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            LIMITACIÓN DE LICENCIAS DE USO – nuestras certificaciones no
            extienden ningún derecho o licencia de uso para que las alumnas
            puedan impartir sus propios cursos por fuera de las certificaciones
            que esté o haya tomado.&nbsp;
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            CERTIFICACIÓN TBM – nuestras alumnas están acreditadas por The Body
            Method en una amplia gama de certificaciones y dichas acreditaciones
            sí se pueden divulgar como parte de la formación integral de
            nuestras alumnas.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            RESTRICCIÓN SOBRE USO DEL NOMBRE – a fin de proteger todos los
            derechos de propiedad intelectual y los derechos de autoría,{" "}
          </span>
          <i>
            <span>
              que recaen sobre la protección legal del proceso creativo de todos
              nuestros cursos y certificaciones
            </span>
          </i>
          <span>
            , está estrictamente prohibido el uso del nombre The Body Method
            para efectos comerciales que pudieran afectar la titularidad y
            nuestro uso exclusivo de marca.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            USO CORRECTO DE CERTIFICACIONES (REFERENCIAS) – las alumnas pueden
            utilizar para referir que validaron una de nuestras certificaciones
            las siguientes leyendas: ”Certificada por The Body Method*” o “TBM
            Certified*”
          </span>
        </span>
      </p>
      <p>
        <span>
          <span lang="ES-TRAD" dir="ltr">
            *Solo pueden utilizar los ejemplos antes mencionados si adquiriste,
            cursaste y aprobaste la certificación
          </span>
        </span>
        <span>
          <span lang="ES-TRAD" dir="ltr">
            .
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            TÉCNICA, TEORÍA Y METOLODOGÍA - toda usuaria de las múltiples
            certificaciones de la plataforma de The Body Method, tienen acceso
            confidencial de todos los conocimientos sobre sus metodología,
            técnicas y teorías para que pueda convertirse potencialmente en
            coach de las disciplinas mencionadas e implementando todo lo
            aprendido en la certificación.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            DIFAMACIÓN – está prohibido difamar la reputación de la
            certificación, el método o el estudio mediante declaraciones falsas
            (verbales o escritas) que busquen un perjuicio.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            VIGENCIA – después de que vence la vigencia estipulada de cualquier
            certificación adquirida, no se podrá seguir teniendo acceso continuo
            a la misma.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            EXTENSIÓN DE VIGENCIA – la extensión de acceso para cualquier
            certificación tendrá un costo de MX$500 (
          </span>
          <i>
            <span>quinientos pesos 00/100 MN</span>
          </i>
          <span>
            ) el cual prolonga el acceso por una extensión de 03 semanas
            adicionales.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            APROBACIÓN DE CERTIFICACIONES – acreditar la compra de una
            certificación no garantiza en automático la aprobación de la misma
            únicamente por haberla tomado; es necesario concluir exitosamente
            todos los quizzes teóricos de cada módulo, entregar y aprobar el
            examen final (evaluación práctica) el cual será revisado por una
            master coach.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            CALIFICACIÓN DE EVALUACIÓN PRÁCTIVA – en el examen final (evaluación
            práctica) se generará una retroalimentación de parte de una master
            coach y la calificación deberá ser de 70 o mayor para la aprobación
            de la certificación correspondiente.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            OPORTUNIDAD DE APROBACIÓN – en caso de que la calificación de la
            evaluación práctica no fuera aprobatoria en el primer intento, se
            tendrá una segunda oportunidad; posterior a la retroalimentación por
            parte de una master coach, se activará nuevamente por la cantidad de
            días de acceso incluidos en la certificación adquirida contando a
            partir de la fecha de revisión de su examen práctico. Esta segunda
            oportunidad se otorga para que se puedan regenerar estudios y
            repasos para nuevamente gestionar una entrega de examen final
            (evaluación práctica).&nbsp;
          </span>
        </span>
      </p>
      <p>
        <span>
          <span lang="ES-TRAD" dir="ltr">
            En caso de que no se apruebe el segundo intento, se enviará un
            reconocimiento por haber participado en TBM Certifications.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            NO REEMBOLSOS - el costo de las certificaciones incluye el material
            de apoyo, acceso a la plataforma digital y las asesorías de nuestras
            master coaches; por tal motivo existe la política de no reembolsos.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            PASE DE OPORTUNIDAD – sobre sesiones que no hayan podido tomarse en
            las fechas inscritas para certificaciones en vivo, se tendrá un pase
            de una oportunidad únicamente para mover la fecha de certificación,
            siempre y cuando se haya pedido realizar dicho cambio vía WhatsApp.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            PAUSA DE CERTIFICACIÓN POR LESIÓN - en el caso de que una alumna
            sufra una lesión, un accidente o algo que impida continuar cursando
            su certificación, se acreditará a su favor el derecho a congelar su
            acceso,{" "}
          </span>
          <i>
            <span>
              por lo que es necesario que se notifique al momento a la master
              coach correspondiente
            </span>
          </i>
          <span>.</span>
        </span>
      </p>
      <p>
        <span>
          <span lang="ES-TRAD"></span>
        </span>
      </p>
      <p>
        <span>
          <span>
            Únicamente se puede congelar el acceso para que la alumna pueda
            cursarlo cuando le sea posible nuevamente; más no se puede extender
            dicho acceso fuera de la vigencia del mismo.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            DIRECTORIO OFICIAL DE INSTRUCTORAS – acreditar las certificaciones
            de{" "}
          </span>
          <i>
            <span>Sculpt and Burn</span>
          </i>
          <span>, </span>
          <i>
            <span>Barre</span>
          </i>
          <span> y </span>
          <i>
            <span>Jump</span>
          </i>
          <span>
            , generan el derecho de aparecer en el directorio oficial de
            instructoras certificadas en nuestra página web, siempre y cuando
            exista consentimiento en firmar una autorización digital para
            aparecer en este listado público, llenando un formulario e
            incluyendo los datos que se desean compartir.
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            Si existieran clientes o estudios interesados en contratar
            instructoras certificadas, podrán consultar el Directorio Oficial de
            Instructoras para buscar clases con una instructora
            certificada.&nbsp;
          </span>
        </span>
      </p>
      <p>
        <span>
          <span>
            El Directorio de Instructoras es nuestra herramienta para validar
            quién tiene la certificación oficial.
          </span>
        </span>
      </p>
    </div>
  );
};

export default GeneralClauses;
