import React, { useContext, useEffect } from "react";
import ProgressCourse from "../components/courses/ProgressCourse";
import SingleSection from "../components/courses/SingleSection";
import SingleLesson from "../components/lessons/SingleLesson";
import MaterialHelp from "../components/ayuda/MaterialHelp";
import { CoursesContext } from "../context/CoursesContext";
import CourseNotes from "../components/notes/CourseNotes";
import LineContact from "../components/line/LineContact";
import Sections from "../components/courses/Sections";
import { AuthContext } from "../context/AuthContext";
import { Router } from "@reach/router";
import { getValue } from "../utils";
import { ModalContext } from "../context/ModalContext";
import AcceptTermsModal from "../components/global/AcceptTermsModal";
import BBPClauses from "../components/global/BBPClauses";

const ACCEPT_TIMEOUT = 500;

const SingleCourse = ({ course_id }) => {
  const { course, getCourse, clearCourse } = useContext(CoursesContext);
  const { modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    clearCourse();
    getCourse(course_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course_id]);

  useEffect(() => {
    if (user !== null) {
      getCourse(course_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if(parseInt(course_id) === 6 && course && course !== null) {
      handleBBPTerms();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  useEffect(() => {
    return clearCourse;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBBPTerms = () => {
    const acceptedClauses = getValue(user, "acceptedBBP");
    if (!acceptedClauses) {
      return setTimeout(() => {
        modalComponent(
          "Aceptar Cláusulas Especiales",
          <AcceptTermsModal
            acceptKey="acceptedBBP"
            name="Cláusulas Especiales Block Burn Pilates"
          >
            <BBPClauses />
          </AcceptTermsModal>
        );
      }, ACCEPT_TIMEOUT);
    }
  }

  const renderContent = () => {
    if (course && course !== null) {
      if (user !== null) {
        return (
          <div className="position-relative">
            <CourseNotes />
            <ProgressCourse course_id={course.course_id} course={course} />
            <div className="course-content">
              <Router>
                <Sections path="/" course_id={course_id} />
                <SingleSection
                  path="/sections/:section_id"
                  course_id={course_id}
                />
                <SingleLesson path="/sections/:section_id/lessons/:lesson_id" />
              </Router>
            </div>
            <MaterialHelp />
            <LineContact />
          </div>
        );
      }
    }
    return (
      <div className="container-fluid px-0 text-center my-5">
        <div className="spinner-border"></div>
      </div>
    );
  };

  return <div className="container-fluid px-0">{renderContent()}</div>;
};

export default SingleCourse;
