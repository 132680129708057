import React, { useContext, useEffect } from "react";
import { Router } from "@reach/router";
import { AuthContext } from "../context/AuthContext";
import MiInformacion from "./MiInformacion";
import MisMetodos from "./MisMetodos";
import MobileMenu from "../components/common/MobileMenu";
import PanelNavbar from "../components/global/PanelNavbar";
import Footer from "../components/global/Footer";
import Purchases from "./Purchases";
import Invoices from "./Invoices";
import SingleCourse from "./SingleCourse";
import PanelHome from "./PanelHome";
import CourseAccesses from "./CourseAccesses";
import SingleAttempt from "./SingleAttempt";
import { ModalContext } from "../context/ModalContext";
import { CourseAccessesContext } from "../context/CourseAccessesContext";
import { navigate } from "@reach/router";
import AcceptTermsModal from "../components/global/AcceptTermsModal";
import Terms from "../components/global/Terms";
import { getValue } from "../utils";
import GeneralClauses from "../components/global/GeneralClauses";

const ACCEPT_TIMEOUT = 500;

const Panel = () => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { getCourseAccesses } = useContext(CourseAccessesContext);
  const { user, signOut } = useContext(AuthContext);

  useEffect(() => {
    getCourseAccesses();
    handleUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleInfo = () => {
    navigate("/mytbm/informacion");
    clearModal();
  };

  const handleTerms = () => {
    const acceptedTerms = getValue(user, "acceptedTerms");
    if (!acceptedTerms) {
      return setTimeout(() => {
        modalComponent(
          "Aceptar Términos y Condiciones",
          <AcceptTermsModal
            acceptKey="acceptedTerms"
            name="Términos y Condiciones"
          >
            <Terms />
          </AcceptTermsModal>
        );
      }, ACCEPT_TIMEOUT);
    }

    const acceptedClauses = getValue(user, "acceptedClauses");
    if (!acceptedClauses) {
      return setTimeout(() => {
        modalComponent(
          "Aceptar Cláusulas Generales",
          <AcceptTermsModal
            acceptKey="acceptedClauses"
            name="Cláusulas Generales"
          >
            <GeneralClauses />
          </AcceptTermsModal>
        );
      }, ACCEPT_TIMEOUT);
    }
  };

  const handleUserInfo = () => {
    const completedAddress = user.address.street !== null;
    const completeInfo =
      user.birthdate !== null && user.last_name !== null && user.phone !== null;
    if (!completedAddress || !completeInfo) {
      return modalComponent(
        "Completa tu Información",
        <div>
          <p>
            Debes completar tu información personal antes de acceder a tus
            certificaciones.
          </p>
          <button onClick={handleInfo} className="btn btn-primary w-100">
            Completar Información
          </button>
        </div>
      );
    }

    handleTerms();
  };

  return (
    <div className="container-fluid px-0">
      <MobileMenu signOut={signOut} user={user} />
      <PanelNavbar user={user} signOut={signOut} />
      <div className="content overflow-x-hidden">
        <Router>
          <SingleAttempt path="/attempt/:attempt_id/*" />
          <SingleCourse path="/course/:course_id/*" />
          <MiInformacion path="/informacion" />
          <CourseAccesses path="/accesos" />
          <Purchases path="/membresias" />
          <MisMetodos path="/metodos" />
          <Invoices path="/pagos" />
          <PanelHome path="/" />
        </Router>
      </div>
      <Footer />
    </div>
  );
};

export default Panel;
